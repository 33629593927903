@font-face {
  font-family: 'Prohibition';
  src: url('./assets//fonts/Prohibition-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets//fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-display: swap;
  src: local('Pretendard Black'), url('./assets/fonts/woff2/Pretendard-Black.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-display: swap;
  src: local('Pretendard ExtraBold'), url('./assets/fonts/woff2/Pretendard-ExtraBold.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-display: swap;
  src: local('Pretendard Bold'), url('./assets/fonts/woff2/Pretendard-Bold.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-display: swap;
  src: local('Pretendard SemiBold'), url('./assets/fonts/woff2/Pretendard-SemiBold.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-display: swap;
  src: local('Pretendard Medium'), url('./assets/fonts/woff2/Pretendard-Medium.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-display: swap;
  src: local('Pretendard Regular'), url('./assets/fonts/woff2/Pretendard-Regular.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-display: swap;
  src: local('Pretendard Light'), url('./assets/fonts/woff2/Pretendard-Light.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-display: swap;
  src: local('Pretendard ExtraLight'), url('./assets/fonts/woff2/Pretendard-ExtraLight.woff2') format('font-woff2'),
    url('./assets/fonts/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'ChakraPetch';
  font-weight: 700;
  font-display: swap;
  src: local('ChakraPetch Bold'), url('./assets/fonts/woff2/ChakraPetch-Bold.woff2') format('font-woff2'),
    url('./assets/fonts/woff/ChakraPetch-Bold.woff') format('woff'),
    url('./assets/fonts/ttf/ChakraPetch-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch';
  font-weight: 600;
  font-display: swap;
  src: local('ChakraPetch SemiBold'), url('./assets/fonts/woff2/ChakraPetch-SemiBold.woff2') format('font-woff2'),
    url('./assets/fonts/woff/ChakraPetch-SemiBold.woff') format('woff'),
    url('./assets/fonts/ttf/ChakraPetch-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ChakraPetch';
  font-weight: 300;
  font-display: swap;
  src: local('ChakraPetch Light'), url('./assets/fonts/woff2/ChakraPetch-Light.woff2') format('font-woff2'),
    url('./assets/fonts/woff/ChakraPetch-Light.woff') format('woff'),
    url('./assets/fonts/ttf/ChakraPetch-Light.ttf') format('truetype');
}
